import org.w3c.xhr.XMLHttpRequest
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.*
import kotlin.random.Random

var videos = ArrayList<String>()
var vidStack = ArrayList<String>()
var stackPos = -1
var ytPlayer: dynamic = null

val nextBtn = document.getElementById("nextBtn") as HTMLDivElement
val prevBtn = document.getElementById("lastBtn") as HTMLDivElement
val loadingScreenDiv = document.getElementById("loadingScreen") as HTMLDivElement
val loadingSentence = document.getElementById("loadingSentence") as HTMLParagraphElement
val discordPopUp = document.getElementById("discordPopUp") as HTMLDivElement
val closer = document.getElementById("closer") as HTMLDivElement
fun main() {
    nextBtn.hide()
    prevBtn.hide()
    loadingScreenDiv.show()


    initBtnListeners()

    val xhttp: dynamic = XMLHttpRequest()
    //TODO change key to production Key!!!
    xhttp.open("GET", "https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails&maxResults=50&playlistId=UUGY2E83PapX47mviakM_IpQ&fields=items%2FcontentDetails%2FvideoId%2CnextPageToken&key=AIzaSyBZZLpGefN32OJsNgyszNBIaSzFsoECTm4", true)
    xhttp.onreadystatechange = fun() {
        if (xhttp.readyState == 4) {
            val res = xhttp.responseText as String
            getFirstVideos(res)
        }
    }
    xhttp.send()

}

fun makeVideo(id: String) {
    if (document.getElementById("output") is HTMLIFrameElement) {
        ytPlayer.loadVideoById(id)
    } else {
        if (stackPos == -1) {
            js("var player;\n" +
                    "player = new YT.Player('output', {\n" +
                    "    width: \$(window).width(),\n" +
                    "    height: \$(window).height(),\n" +
                    "    videoId: id,\n" +
                    "    playerVars: {\n" +
                    "        rel: 0,\n" +
                    "        vq: 'large'\n" +
                    "    },\n" +
                    "    events: {\n" +
                    "        onStateChange: aminium4.onPlayerStateChange\n" +
                    "    }\n" +
                    "});\n" +
                    "aminium4.setPlayer(player);\n")
        } else {
            js("var player;\n" +
                    "player = new YT.Player('output', {\n" +
                    "    width: \$(window).width(),\n" +
                    "    height: \$(window).height(),\n" +
                    "    videoId: id,\n" +
                    "    playerVars: {\n" +
                    "        autoplay: 1,\n" +
                    "        rel: 0,\n" +
                    "        vq: 'large'\n" +
                    "    },\n" +
                    "    events: {\n" +
                    "        onStateChange: aminium4.onPlayerStateChange\n" +
                    "    }\n" +
                    "});\n" +
                    "aminium4.setPlayer(player);\n")
        }
    }
}

@Suppress("unused")
@JsName("setPlayer")
fun setPlayer(element: dynamic) {
    ytPlayer = element
}

@Suppress("unused")
@JsName("onPlayerStateChange")
fun onPlayerStateChange(event: dynamic) {
    if (event.data == 0) {
        nextVideo()
    }
}

fun getFirstVideos(res: String) {
    res.split("\"videoId\": \"").forEach {
        videos.add(it.substring(0..10))
    }
    res.split("\"items\": [")[1]
    val nextPageToken = res.split("\"nextPageToken\": \"")[1].split("\",")[0]
    getMoreVideos(nextPageToken)
}

var siteIndex = 0
fun getMoreVideos(token: String) {
    if (token.isNotBlank()) {
        val xhttp: dynamic = XMLHttpRequest()
        //TODO change key to production Key!!!
        xhttp.open("GET", "https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails&pageToken=$token&maxResults=50&playlistId=UUGY2E83PapX47mviakM_IpQ&fields=items%2FcontentDetails%2FvideoId%2CnextPageToken&key=AIzaSyBZZLpGefN32OJsNgyszNBIaSzFsoECTm4", true)
        xhttp.onreadystatechange = fun() {
            if (xhttp.readyState == 4) {
                println("getting next")
                val res = xhttp.responseText as String
                try {
                    res.split("\"videoId\": \"").forEach {
                        videos.add(it.substring(0..10))
                    }
                    siteIndex += 1
                    if (siteIndex == 15) {
                        (document.getElementById("loadingScreenH1") as HTMLSpanElement).show()
                    }
                    getMoreVideos(res.split("\"nextPageToken\": \"")[1].split("\",")[0])
                } catch (e: Exception) {
                    println("No more Videos!")
                    val zL = ArrayList<String>()
                    for (v in videos) {
                        if (v.startsWith("{")) {
                            zL.add(v)
                        }
                    }
                    videos.removeAll(zL)
                    val z = Random.nextInt(videos.size)
                    makeVideo(videos[z])
                    vidStack.add(videos[z])
                    println("${videos.size} videos found")
                    discordPop()
                }
            }
        }
        xhttp.send()

    } else {
        window.alert("Something went wrong! We're sorry")
        println("Something went wrong!")
    }
}

fun nextVideo() {
    when {
        stackPos == -1 -> {
            playerMove("in")
            stackPos += 1
            ytPlayer.playVideo()
        }
        stackPos < vidStack.lastIndex -> {
            playerMove("in")
            stackPos += 1
            makeVideo(vidStack[stackPos])
        }
        else -> {
            playerMove("in")
            var nbr: Int
            do {
                nbr = Random.nextInt(videos.size)
            } while (vidStack.takeLast(5).contains(videos[nbr]))
            vidStack.add(videos[nbr])
            stackPos += 1
            makeVideo(vidStack[stackPos])
        }
    }
}

fun prevVideo() {
    when {
        stackPos > 0 -> {
            stackPos -= 1
            makeVideo(vidStack[stackPos])
        }
        stackPos == 0 -> {
            stackPos = -1
            playerMove("out")
            ytPlayer.pauseVideo()
        }
    }
}

fun initBtnListeners() {
    (document.getElementById("nextBtn") as HTMLDivElement).addEventListener("click", {
        nextVideo()
    })
    (document.getElementById("lastBtn") as HTMLDivElement).addEventListener("click", {
        prevVideo()
    })
    (document.getElementById("wrapBtn") as HTMLDivElement).addEventListener("click", {
        discordGone()
    })
    (document.getElementById("closer") as HTMLDivElement).addEventListener("click", {
        discordGone()
    })
    discordPopUp.addEventListener("click", {
        discordGone()
    })
}

fun playerMove(way: String) {
    when (way) {
        "in" -> {
            js("\$(\"#output\").animate({\n" +
                    "    left: \"0%\",\n" +
                    "}, 500);")
        }
        "out" -> {
            js("\$(\"#output\").animate({\n" +
                    "    left: \"100%\",\n" +
                    "}, 500);")
        }
    }

}

fun discordPop(){
    loadingSentence.hide()
    discordPopUp.show()
    closer.show()
    discordPopUp.style.top = "23vh"
}

fun discordGone(){
    discordPopUp.style.top = "36vh"
    discordPopUp.hide()
    loadingScreenDiv.hide()
    closer.hide()
    nextBtn.show()
    prevBtn.show()
}

private fun HTMLElement.show() {
    this.style.visibility = "visible"
    this.style.opacity = "1.0"
}

private fun HTMLElement.hide() {
    this.style.opacity = "0.0"
    window.setTimeout({ this.style.visibility = "hidden" }, 200)
}